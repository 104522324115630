import $ from 'jquery';
import qs from 'qs';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    sendContactus, setLayout, setPath, setPathLayout,
    getPoliciesBasedOnAge,
    getTermsBasedOnPolicy,
    getSumAssuredValidation,
    getCalculations,
    customerPoricyNid,
    policyAdd,
    policyNomineeAdd,
} from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';

import DatePicker from "react-datepicker";
import { DateInput } from '../contactus/Dateinput';
import "react-datepicker/dist/react-datepicker.css";
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { withRouter } from 'react-router-dom';
import LangData from '../common/LangData'
import Cookies from 'js-cookie'

class Policyform extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nid_validation:false,
            nid_validation_err_msg:true,
            validator: {
                policy_holder_dob : '',
                policy_id: '',
                term: '',
                sum_assured: '',
                parentage: '',
                accidental_cover: '',
                payment_cycle_mode: '',
                premium_amount: '',
                policy_holder_name: '',
                policy_holder_gender: '',
                policy_holder_nid: '',
                policy_holder_father_name: '',
                policy_holder_mother_name: '',
                policy_holder_email: '',
                policy_holder_mobile_number: '',
                policy_holder_spouse: '',
                policy_holder_parmanent_address: '',
                policy_holder_medical_practitioner: '',
                policy_holder_medical_practitioner_details: '',
                policy_holder_admitted_hospital: '',
                policy_holder_admitted_hospital_details: '',
                policy_holder_remained_absent: '',
                policy_holder_remained_absent_details: '',
                policy_holder_suffering_ailments_pertaining: '',
                policy_holder_suffering_ailments_pertaining_details: '',
                policy_holder_suffered_diabetes_others: '',
                policy_holder_suffered_diabetes_other_details: '',
                policy_holder_bodily_defect_deformity: '',
                policy_holder_bodily_defect_deformity_details: '',
                policy_holder_accident_injury: '',
                policy_holder_accident_injury_details: '',
                policy_holder_used_alcoholic_others: '',
                policy_holder_used_alcoholic_other_details: '',
                policy_holder_good_health: '',
                policy_holder_good_health_details: '',
                policy_holder_aids_hepatitis: '',
                policy_holder_aids_hepatitis_details: '',
                nominee_name: '',
                nominee_policy_holder_dob: '',
                nominee_father_name: '',
                nominee_mother_name: '',
                nominee_spouse: '',
                nominee_nid: '',
                nominee_permanent_address: '',
                customer_id: '',
            },
            message: '',
            message_type: '',
            policy: {},
            terms: {},
            modedata: {},
            styleinfo: {
                accidental: 'none',
                licterm: 'block',
                parentage: 'none',
            },
            calculation: null,
            formdata: {
                policy_holder_medical_practitioner: 0,
                policy_holder_admitted_hospital: 0,
                policy_holder_remained_absent: 0,
                policy_holder_suffering_ailments_pertaining: 0,
                policy_holder_suffered_diabetes_others: 0,
                policy_holder_bodily_defect_deformity: 0,
                policy_holder_accident_injury: 0,
                policy_holder_used_alcoholic_others: 0,
                policy_holder_good_health: 0,
                policy_holder_aids_hepatitis: 0,

            },
            policyholder_nid:null,
            policyholder_nominee_nid:null,
            tab_active:1

        };
        this.refs = React.createRef();

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        })
    }

    componentDidMount = () => {
        const {search} = this.props.location;
        let is_nominee = 1;
        
        var getdata = search.split("&");
        console.log("getdata ",getdata);
        if (getdata.includes("nominee=true")) {
            is_nominee = 2;
        }
        console.log("policy nominee",is_nominee);
        this.setState({tab_active:is_nominee});
    }

    handleCalculator = () => {
        var parentage = $("#parent_age").val();
        var styleinfo = this.state.styleinfo;
        const userFormData = {
            dob: $("#dateofbirth").val(),
            policy: this.policy_id.value,
            term: styleinfo.licterm == 'block' ? this.term.value : 0,
            sum_assured: this.sum_assured.value,
            parentage: (parentage && styleinfo.parentage == 'block') ? parentage : 0,
            accidental_cover: styleinfo.accidental == 'block' ? this.accidental_cover.value : 0,
            mode: this.mode.value,
            phone_number: ''
        }

        const validator = this.state.validator;

        const resetValidator = {
            policy_holder_dob: '',
            policy_id: '',
            term: '',
            sum_assured: '',
            parentage: '',
            accidental_cover: '',
            payment_cycle_mode: '',
        }
        var validation = {};

        if (userFormData.dob == '') {
            validation.policy_holder_dob = 'This field is required.';
        }

        if (userFormData.policy == '') {
            validation.policy_id = 'This field is required.';
        }

        if (userFormData.term == '' && styleinfo.licterm == 'block') {
            validation.term = 'This field is required.';
        }
        if (userFormData.sum_assured == '') {
            validation.sum_assured = 'This field is required.';
        }
        if (userFormData.parentage == '' && styleinfo.parentage == 'block') {
            validation.parentage = 'This field is required.';
        }
        if (userFormData.accidental_cover == '' && styleinfo.accidental == 'block') {
            validation.accidental_cover = 'This field is required.';
        }
        if (userFormData.mode == '') {
            validation.payment_cycle_mode= 'This field is required.';
        }

        if (Object.keys(validation).length) {
            this.setState({ validator: { ...this.state.validator, ...validation }, message: '' });

        }
        else {

            this.props.getCalculations(userFormData).then((res) => {

                // this.sum_assured.value = '';
                // this.accidental_cover.value = '';
                // this.mode.value = '';
                // $("#parent_age").val('');
                // $("#dateofbirth").val('');

                this.setState({ calculation: res, message: '', message_type: 'success', validator: {...validator,...resetValidator } })
            })
        }
    }

    handleSubmit = () => {
        console.log(this.state.nid_validation)
        if(this.state.nid_validation){
            this.setState({
                nid_validation_err_msg : true
            })
        }else{
            this.setState({
                nid_validation_err_msg : false
            })
        }

        var parentage = $("#parent_age").val();

        console.log('parentage')
        console.log(parentage)

        const { formdata, styleinfo,validator } = this.state;

        console.log('formData')
        console.log(formdata)
        console.log('styleinfo')
        console.log(styleinfo)
        console.log('validator')
        console.log(validator)

        const userFormData = {
            policy_holder_dob: $("#dateofbirth").val(),
            policy_id: this.policy_id.value,
            term: styleinfo.licterm == 'block' ? this.term.value : 0,
            sum_assured: this.sum_assured.value,
            parentage: (parentage && styleinfo.parentage == 'block') ? parentage : 0,
            accidental_cover: styleinfo.accidental == 'block' ? this.accidental_cover.value : 0,
            payment_cycle_mode: this.mode.value,
            premium_amount: this.premium_amount.value,
            policy_holder_name: this.policy_holder_name.value,
            policy_holder_gender: this.policy_holder_gender.value,
            policy_holder_nid: this.policy_holder_nid.value,
            policy_holder_father_name: this.policy_holder_father_name.value,
            policy_holder_mother_name: this.policy_holder_mother_name.value,
            policy_holder_email: this.policy_holder_email.value,
            policy_holder_mobile_number: this.policy_holder_mobile_number.value,
            policy_holder_spouse: this.policy_holder_spouse.value,
            policy_holder_parmanent_address: this.policy_holder_parmanent_address.value,
            policy_holder_medical_practitioner: formdata.policy_holder_medical_practitioner,
            policy_holder_medical_practitioner_details: formdata.policy_holder_medical_practitioner == 1 ? this.policy_holder_medical_practitioner_details.value : '',
            policy_holder_admitted_hospital: formdata.policy_holder_admitted_hospital,
            policy_holder_admitted_hospital_details: formdata.policy_holder_admitted_hospital == 1 ? this.policy_holder_admitted_hospital_details.value : '',
            policy_holder_remained_absent: formdata.policy_holder_remained_absent,
            policy_holder_remained_absent_details: formdata.policy_holder_remained_absent ? this.policy_holder_remained_absent_details.value : '',
            policy_holder_suffering_ailments_pertaining: formdata.policy_holder_suffering_ailments_pertaining,
            policy_holder_suffering_ailments_pertaining_details: formdata.policy_holder_suffering_ailments_pertaining == 1 ? this.policy_holder_suffering_ailments_pertaining_details.value : '',
            policy_holder_suffered_diabetes_others: formdata.policy_holder_suffered_diabetes_others,
            policy_holder_suffered_diabetes_other_details: formdata.policy_holder_suffered_diabetes_others == 1 ? this.policy_holder_suffered_diabetes_other_details.value : '',
            policy_holder_bodily_defect_deformity: formdata.policy_holder_bodily_defect_deformity,
            policy_holder_bodily_defect_deformity_details: formdata.policy_holder_bodily_defect_deformity == 1 ? this.policy_holder_bodily_defect_deformity_details.value : '',
            policy_holder_accident_injury: formdata.policy_holder_accident_injury,
            policy_holder_accident_injury_details: formdata.policy_holder_accident_injury ? this.policy_holder_accident_injury_details.value : '',
            policy_holder_used_alcoholic_others: formdata.policy_holder_used_alcoholic_others,
            policy_holder_used_alcoholic_other_details: formdata.policy_holder_used_alcoholic_others == 1 ? this.policy_holder_used_alcoholic_other_details.value : '',
            policy_holder_good_health: formdata.policy_holder_good_health,
            policy_holder_good_health_details: formdata.policy_holder_good_health == 1 ? this.policy_holder_good_health_details.value : '',
            policy_holder_aids_hepatitis: formdata.policy_holder_aids_hepatitis,
            policy_holder_aids_hepatitis_details: formdata.policy_holder_aids_hepatitis == 1 ? this.policy_holder_aids_hepatitis_details.value : '',
            registered_by: 1,
            nominee_name: this.nominee_name.value,
            nominee_dob: $('#nominee_dob').val(),
            nominee_father_name: this.nominee_father_name.value,
            nominee_mother_name: this.nominee_mother_name.value,
            nominee_spouse: this.nominee_spouse.value,
            nominee_nid: this.nominee_nid.value,
            nominee_permanent_address: this.nominee_permanent_address.value,
        }

        // this.handlePayOnline(userFormData);

        // return false;
        const resetValidator = {
            policy_holder_dob: '',
            policy_id: '',
            term: '',
            sum_assured: '',
            parentage: '',
            accidental_cover: '',
            payment_cycle_mode: '',
        }
        var validation = {};

        if (userFormData.policy_holder_dob == '') {
           // validation.policy_holder_dob = 'This field is required.';
        }

        if (userFormData.policy_id == '') {
           // validation.policy_id = 'This field is required.';
        }

        if (userFormData.term == '' && styleinfo.licterm == 'block') {
          //  validation.term = 'This field is required.';
        }
        if (userFormData.sum_assured == '') {
           // validation.sum_assured = 'This field is required.';
        }
        if (userFormData.parentage == '' && styleinfo.parentage == 'block') {
           // validation.parentage = 'This field is required.';
        }
        if (userFormData.accidental_cover == '' && styleinfo.accidental == 'block') {
           // validation.accidental_cover = 'This field is required.';
        }
        if (userFormData.payment_cycle_mode == '') {
           // validation.payment_cycle_mode= 'This field is required.';
        }

        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '',tab_active:1 });
        }
        else {
            var error_validate = {};
            this.props.policyAdd(userFormData).then((res) => {

                // this.sum_assured.value = '';
                // this.accidental_cover.value = '';
                // this.payment_cycle_mode.value = '';
                // $("#parent_age").val('');
                // $("#dateofbirth").val('');
                this.setState({ message: '', message_type: 'success', validator: { ...resetValidator },tab_active:1 })
                console.log("Hello Mobarak",userFormData);
                this.handlePayOnline(res);
            })
            .catch(error => {
                console.log("Hello Policy form =",error);
                if (error.response) {
                    var html = '';
                    if (Object.keys(error.response.data).length) {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                            html += value+'</br>';
                            if (typeof validator[key] != "undefined") {
                                // error_validate[key] = `${value}`;
                                error_validate[`${key}`] = `${value}`;
                                // Object.assign(error_validate, {key: value});
                             }
                            
                        }

                    }
                    // console.log(' error.response.data ',error.response.data);
                    // console.log(' error.response.status ',error.response.status);
                    // console.log('error.response.headers ',error.response.headers);
                }

                console.log("error_validate =",error_validate);
                console.log("validator =",validator);
                this.setState({validator:{...validation,...error_validate},tab_active:1})
            })
        }
    }
    handleNomineeSubmit = () => {

       
       
        const { formdata, styleinfo,validator } = this.state;
        var message = '';
        const userFormData = {
            nominee_name: this.nominee_name.value,
            nominee_dob: $('#nominee_dob').val(),
            nominee_father_name: this.nominee_father_name.value,
            nominee_mother_name: this.nominee_mother_name.value,
            nominee_spouse: this.nominee_spouse.value,
            nominee_nid: this.nominee_nid.value,
            nominee_permanent_address: this.nominee_permanent_address.value,
            customer_id:Cookies.get('cus_id')?Cookies.get('cus_id'):'',
            nominee_gender:this.nominee_gender.value,
        }

        const resetValidator = {
            nominee_name: '',
            nominee_dob: '',
            nominee_father_name: '',
            nominee_mother_name: '',
            nominee_spouse: '',
            nominee_nid: '',
            nominee_permanent_address: '',
            customer_id: '',
            nominee_gender: '',
        }
        var validation = {};

        if (userFormData.nominee_name == '') {
           validation.nominee_name = 'This field is required.';
        }

        if (userFormData.nominee_dob == '') {
           validation.nominee_dob = 'This field is required.';
        }

        if (userFormData.nominee_father_name == '') {
           validation.nominee_father_name = 'This field is required.';
        }
        if (userFormData.nominee_mother_name == '') {
           validation.nominee_mother_name = 'This field is required.';
        }
        if (userFormData.nominee_spouse == '') {
           validation.nominee_spouse = 'This field is required.';
        }
        if (userFormData.nominee_nid == '') {
           validation.nominee_nid = 'This field is required.';
        }
        if (userFormData.nominee_permanent_address == '') {
           validation.nominee_permanent_address= 'This field is required.';
        }
        if (userFormData.customer_id == '') {
            message= 'At first add your application information then add your nominee information..';
        }

        if (Object.keys(validation).length) {
            this.setState({ validator: { ...this.state.validator, ...validation }, message: message,tab_active:2 });
        }
        else {
            var error_validate = {};
            this.props.policyNomineeAdd(userFormData).then((res) => {
                $("#nominee_dob").val('');
                this.setState({ message: res.message, message_type: 'success', validator: { ...resetValidator },policyholder_nominee_nid:null,tab_active:2 })
                Cookies.remove('cus_id');
                $('#policyform').trigger("reset");
            })
            .catch(error => {
                // console.log("Hello Policy form =",error);
                if (error.response) {
                    var html = '';
                    if (Object.keys(error.response.data).length) {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                            html += value+'</br>';
                            if (typeof validator[key] != "undefined") {
                                // error_validate[key] = `${value}`;
                                error_validate[`${key}`] = `${value}`;
                                // Object.assign(error_validate, {key: value});
                             }
                             if(typeof validator[key] != "customer_id" ) {
                                this.setState({message:`${value}`,message_type:'danger'})
                             }
                            
                        }

                    }
                    // console.log(' error.response.data ',error.response.data);
                    // console.log(' error.response.status ',error.response.status);
                    // console.log('error.response.headers ',error.response.headers);
                }

                console.log("error_validate =",error_validate);
                console.log("validator =",validator);
                this.setState({validator:{...validation,...error_validate},tab_active:2})
            })
        }
    }

    handleValidatonRest = () => {
        const resetValidator = {
                policy_holder_dob : '',
                policy_id: '',
                term: '',
                sum_assured: '',
                parentage: '',
                accidental_cover: '',
                payment_cycle_mode: '',
                premium_amount: '',
                policy_holder_name: '',
                policy_holder_gender: '',
                policy_holder_nid: '',
                policy_holder_father_name: '',
                policy_holder_mother_name: '',
                policy_holder_email: '',
                policy_holder_mobile_number: '',
                policy_holder_spouse: '',
                policy_holder_parmanent_address: '',
                policy_holder_medical_practitioner: '',
                policy_holder_medical_practitioner_details: '',
                policy_holder_admitted_hospital: '',
                policy_holder_admitted_hospital_details: '',
                policy_holder_remained_absent: '',
                policy_holder_remained_absent_details: '',
                policy_holder_suffering_ailments_pertaining: '',
                policy_holder_suffering_ailments_pertaining_details: '',
                policy_holder_suffered_diabetes_others: '',
                policy_holder_suffered_diabetes_other_details: '',
                policy_holder_bodily_defect_deformity: '',
                policy_holder_bodily_defect_deformity_details: '',
                policy_holder_accident_injury: '',
                policy_holder_accident_injury_details: '',
                policy_holder_used_alcoholic_others: '',
                policy_holder_used_alcoholic_other_details: '',
                policy_holder_good_health: '',
                policy_holder_good_health_details: '',
                policy_holder_aids_hepatitis: '',
                policy_holder_aids_hepatitis_details: '',
                nominee_name: '',
                nominee_policy_holder_dob: '',
                nominee_father_name: '',
                nominee_mother_name: '',
                nominee_spouse: '',
                nominee_nid: '',
                nominee_permanent_address: '',
        }
        this.setState({ validator: { ...resetValidator } });
    }

    handlePoliciesBasedOnAge = () => {
        this.handleValidatonRest();
        var dob = $("#dateofbirth").val();

        if (dob) {
            this.props.getPoliciesBasedOnAge({ dob: dob }).then(({ policies }) => {
                this.setState({ policy: policies });
            });
            this.handlePorichoy();
        }
    }
    handleTermsBasedOnPolicy = () => {
        this.handleValidatonRest();
        var dob = $("#dateofbirth").val();
        var policy = this.policy_id.value;
        if (dob && policy) {
            this.props.getTermsBasedOnPolicy({ dob: dob, policy: policy }).then(({ terms }) => {
                this.setState({ terms: terms })
            });
            this.handleModeData();
            this.handleOptionShowHide();
        }
    }
    handleSumAssuredValidation = () => {

        //this.handleValidatonRest();
        var policy = this.policy_id.value;
        var sum_assured = this.sum_assured.value;

        if (!policy) {
            this.sum_assured.value = '';
            this.setState({
                validator: {
                    ...this.state.validator,
                    policy_id: 'First you have to select policy'
                }
            })

            return;
        }

        if (sum_assured && policy) {
            this.props.getSumAssuredValidation({ policy: policy, sum_assured: sum_assured }).then((data) => {
                // console.log("getSumAssuredValidation",data);

                if (data.status == 'invalid') {
                    this.setState({
                        validator: {
                            ...this.state.validator,
                            sum_assured: data.msg
                        }
                    })
                }
                else {
                    this.setState({
                        validator: {
                            ...this.state.validator,
                            sum_assured: ''
                        }
                    })
                }

                //this.setState({terms:terms})
            });
        }
    }

    handleModeData = () => {
        var modeData;
        var policy = this.policy_id.value;
        if (!policy) {
            modeData = {};
        }
        else if (policy == '803') {
            modeData = { "5": "Single" };
        }
        else if (policy == '811') {
            modeData = { "1": "Yearly", "2": "Half Yearly" };
        }
        else {
            modeData = { "1": "Yearly", "2": "Half Yearly", "3": "Quarterly", "4": "Monthly" };
        }

        this.setState({ modedata: modeData });
    }
    handleOptionShowHide = () => {

        var policy = this.policy_id.value;
        if (!policy || policy == '807' || policy == '803' || policy == '811') {
            this.setState({
                styleinfo: {
                    ...this.state.styleinfo,
                    accidental: 'block'
                }
            });
        }
        else {
            this.setState({
                styleinfo: {
                    ...this.state.styleinfo,
                    accidental: 'none'
                }
            });
        }


        if (policy == '807') {
            this.setState({
                styleinfo: {
                    ...this.state.styleinfo,
                    licterm: 'none',
                    parentage: 'block',
                }
            });

        } else {
            this.setState({
                styleinfo: {
                    ...this.state.styleinfo,
                    licterm: 'block',
                    parentage: 'none',
                }
            });

        }
    }

    handleRadio = (name, value) => {
        console.log('name =', name, 'value =', value);
        var question = {};
        if (name == 'policy_holder_medical_practitioner') {
            question = { policy_holder_medical_practitioner: value };
        }
        else if (name == 'policy_holder_admitted_hospital') {
            question = { policy_holder_admitted_hospital: value };
        }
        else if (name == 'policy_holder_remained_absent') {
            question = { policy_holder_remained_absent: value };
        }
        else if (name == 'policy_holder_suffering_ailments_pertaining') {
            question = { policy_holder_suffering_ailments_pertaining: value };
        }
        else if (name == 'policy_holder_suffered_diabetes_others') {
            question = { policy_holder_suffered_diabetes_others: value };
        }
        else if (name == 'policy_holder_bodily_defect_deformity') {
            question = { policy_holder_bodily_defect_deformity: value };
        }
        else if (name == 'policy_holder_accident_injury') {
            question = { policy_holder_accident_injury: value };
        }
        else if (name == 'policy_holder_used_alcoholic_others') {
            question = { policy_holder_used_alcoholic_others: value };
        }
        else if (name == 'policy_holder_good_health') {
            question = { policy_holder_good_health: value };
        }
        else if (name == 'policy_holder_aids_hepatitis') {
            question = { policy_holder_aids_hepatitis: value };
        }
        else {
            question = {};
        }

        this.setState({
            formdata: {
                ...this.state.formdata,
                ...question
            }
        })
    }

    handlePorichoy = (e) => {
        //console.log(e.target.value)
        if(e){
            if(e.target.value){
                this.setState({
                 nid_validation:true
                })
             }else{
                 this.setState({
                 nid_validation:false
                })
             }
        }



        let nid = this.policy_holder_nid.value;
        let dob = $("#dateofbirth").val();
        var date_of_birth = this.dateFormate(dob);
      
        const requestData = {
            "national_id": nid,
            "person_dob": date_of_birth,
            "english_translation": true
        }
        const validation = {};
        console.log('nid.length=',nid.length);
        if(nid.length == 10 || nid.length == 13 || nid.length == 17) {
            validation.policy_holder_nid = '';
            this.setState({validator:{...this.state.validator,...validation}})
        
        }
        else {
            if(nid.length > 10) {
                validation.policy_holder_nid = 'NID must be 10, 13 or 17 digits.';
                this.setState({validator:{...this.state.validator,...validation}})
            }
            return false;
        }
        
        if(nid && dob) {
            console.log('nid nominee.if=',nid.length);
            this.props.customerPoricyNid(requestData).then((data)=>{
                if(data.passKyc === 'yes') {
                    this.setState({
                        policyholder_nid:data
                    })
                }else {
                    this.setState({
                        policyholder_nid:null
                    })
                }
            });
        }
    }
    handleNomineePorichoy = () => {
        
        let nid = this.nominee_nid.value;
        let dob = $("#nominee_dob").val();
        var date_of_birth = this.dateFormate(dob);
      
        const requestData = {
            "national_id": nid,
            "person_dob": date_of_birth,
            "english_translation": true
        }
        const validation = {};
        console.log('nid.length nominee=',nid.length);
        if(nid.length == 10 || nid.length == 13 || nid.length == 17) {
            validation.nominee_nid = '';
            this.setState({validator:{...this.state.validator,...validation}})
        
        }
        else {
            if(nid.length > 10) {
                validation.nominee_nid = 'NID must be 10, 13 or 17 digits.';
                this.setState({validator:{...this.state.validator,...validation}})
            }
            return false;
        }
        
        if(nid && dob) {
            console.log('nid.if nominee =',nid.length);
            this.props.customerPoricyNid(requestData).then((data)=>{
                if(data.passKyc === 'yes') {
                    this.setState({
                        policyholder_nominee_nid:data
                    })
                }else {
                    this.setState({
                        policyholder_nominee_nid:null
                    })
                }
            });
        }
    }

    dateFormate = (date) =>{

        var today = new Date(date);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return today = yyyy + '/' + mm + '/' + dd;

    }

    handlePayOnline = (policycustomer) => {

        axiosInstance.post(version+'pay_online',{params:policycustomer}).then(({ data }) => {
            console.log(" hanldePayonline =",data);
          if(data.status =='SUCCESS' && data.GatewayPageURL)
          {
            Cookies.set('cus_id', policycustomer.id);
            window.location.href =data.GatewayPageURL;
          }
    
        });
    }

    render() {

        // const contents = this.props.data.content;
        const { validator, policy, terms, modedata, styleinfo, formdata,policyholder_nid,policyholder_nominee_nid } = this.state;

        const policy_data = Object.entries(policy);
        const terms_data = Object.entries(terms);
        const mode_data = Object.entries(modedata);
        const voter = policyholder_nid?policyholder_nid.voter:null;
        const voter_gender = policyholder_nid?voter.gender:'';
        const nominee_voter = policyholder_nominee_nid?policyholder_nominee_nid.voter:null;
        const nominee_voter_gender = nominee_voter?nominee_voter.gender:'';
        
        const {search} = this.props.location;
        let is_nominee = false;
        
        var getdata = search.split("&");
        console.log("getdata ",getdata);
        if (getdata.includes("nominee=true")) {
            is_nominee = true;
        }
        

       console.log("policy ",this.state);
       console.log("tab_active",this.state.tab_active);
       var tab_active = this.state.tab_active;
       const lang = this.props.lang;
        
        return (
            <Fragment>
               

                <form action="#" id="policyform">
                    <Tabs activeTab={is_nominee?2:tab_active} ulClassName="pro-tab-items" activityClassName="bg-success">
                        <Tab title={lang === 'bn'?'আবেদনকারী’র তথ্য':'Aplicant’s Information'} className="service-tab-nav">
                            <div className="mt-3">

                            {(this.state.message != '' && this.state.message_type == 'success' && tab_active== 1) && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {(this.state.message != '' && this.state.message_type == 'danger' && tab_active== 1) && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}

                                
                                <div className='row'>
                                    <div className="col-md-4">
                                        <label><LangData keyname="label_policy_holder_dob" /> <span>*</span></label>

                                        <DateInput handlePoliciesBasedOnAge={() => this.handlePoliciesBasedOnAge()}
                                            id="dateofbirth"
                                        />

                                        {validator.policy_holder_dob && <span className="help-block form-text text-danger">{validator.policy_holder_dob}</span>}



                                    </div>

                                    <div className="col-md-4">
                                        <label><LangData keyname="label_plan_name" /> <span>*</span></label>
                                        <select ref={(input) => this.policy_id = input} className={validator.policy ? 'form-control from-style input-error' : 'form-control from-style'} onChange={() => this.handleTermsBasedOnPolicy()}>
                                            <option value="">Select Policy</option>
                                            {policy_data.length && <Fragment>

                                                {policy_data.map((row, key) => {
                                                    return (
                                                        <option value={row[0]} key={key}>{row[1]}</option>
                                                    )
                                                })}
                                            </Fragment>}
                                        </select>

                                        {validator.policy_id && <span className="help-block form-text text-danger">{validator.policy_id}</span>}
                                    </div>
                                    <div className="col-md-4" style={{ display: styleinfo.licterm }}>
                                        <label><LangData keyname="label_term" /> <span>*</span></label>

                                        <select ref={(input) => this.term = input} className={validator.term ? 'form-control from-style input-error' : 'form-control from-style'}>
                                            <option value="">Select terms</option>

                                            {terms_data.length && <Fragment>

                                                {terms_data.map((row, key) => {
                                                    return (
                                                        <option value={row[0]} key={key}>{row[1]}</option>
                                                    )
                                                })}
                                            </Fragment>}

                                        </select>
                                        {validator.term && <span className="help-block form-text text-danger">{validator.term}</span>}
                                    </div>
                                    <div className="col-md-4" style={{ display: styleinfo.parentage }}>
                                        <label><LangData keyname="label_parentage" /><span>*</span></label>

                                        <DateInput
                                            id="parent_age"
                                        />

                                        {validator.parentage && <span className="help-block form-text text-danger">{validator.parentage}</span>}
                                    </div>

                                    <div className="col-md-4">
                                        <label><LangData keyname="label_sum_assured" /><span>*</span></label>

                                        <input type="text" ref={(input) => this.sum_assured = input} className={validator.sum_assured ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Sum assured" onInput={() => this.handleSumAssuredValidation()} />
                                        {validator.sum_assured && <span className="help-block form-text text-danger">{validator.sum_assured}</span>}
                                    </div>
                                    <div className="col-md-4">
                                        <label> <LangData keyname="label_payment_cycle_mode" />  <span>*</span></label>

                                        <select ref={(input) => this.mode = input} className={validator.mode ? 'form-control from-style input-error' : 'form-control from-style'}>
                                            <option value="">Select Mode</option>

                                            {mode_data.length && <Fragment>

                                                {mode_data.map((row, key) => {
                                                    return (
                                                        <option value={row[0]} key={key}>{row[1]}</option>
                                                    )
                                                })}
                                            </Fragment>}

                                        </select>
                                        {validator.payment_cycle_mode && <span className="help-block form-text text-danger">{validator.payment_cycle_mode}</span>}
                                    </div>
                                    <div className="col-md-4" style={{ display: styleinfo.accidental }}>
                                        <label><LangData keyname="label_accidental_cover" /> <span>*</span></label>

                                        <select ref={(input) => this.accidental_cover = input} className={validator.accidental_cover ? 'form-control from-style input-error' : 'form-control from-style'}>
                                            <option value="">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        {validator.accidental_cover && <span className="help-block form-text text-danger">{validator.accidental_cover}</span>}
                                    </div>
                                    <div className="col-md-4">
                                        <label> <LangData keyname="label_premium_amount" /></label>
                                        <input type="text" ref={(input) => this.premium_amount = input} className="form-control" value={this.state.calculation ? this.state.calculation.premium_amount : ''} placeholder="Premium" readOnly={true} />

                                        {validator.premium_amount && <span className="help-block form-text text-danger">{validator.premium_amount}</span>}
                                    </div>

                                </div>
                                <input type="button" value={lang ==='bn'?'ক্যালকুলেট':'Calculator'} class="btn btn-secondary" onClick={() => this.handleCalculator()} />

                                {/* <input type="button" value="Porichoy" class="btn btn-secondary" onClick={() => this.handlePorichoy()} /> */}

                                <div class="mt-5"></div>

                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                                <LangData keyname="label_policy_holder_medical_practitioner" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_medical_practitioner = input} value="1" checked={formdata.policy_holder_medical_practitioner === 1 ? true : false} onChange={() => this.handleRadio('policy_holder_medical_practitioner', 1)} checked={formdata.policy_holder_medical_practitioner === 1} />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                                
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_medical_practitioner = input} value="0" checked={formdata.policy_holder_medical_practitioner === 0 ? true : false} onChange={() => this.handleRadio('policy_holder_medical_practitioner', 0)} />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_medical_practitioner_details = input} class="form-control" readOnly={formdata.policy_holder_medical_practitioner === 0 ? true : false}></textarea>

                                            {validator.policy_holder_medical_practitioner_details && <span className="help-block form-text text-danger">{validator.policy_holder_medical_practitioner_details}</span>}
                                        </div>
                                    </div>
                                    <hr />
                                </div>

                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                               
                                                <LangData keyname="label_policy_holder_admitted_hospital" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_admitted_hospital = input} value="1" checked={formdata.policy_holder_admitted_hospital === 1 ? true : false} onChange={() => this.handleRadio('policy_holder_admitted_hospital', 1)} />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_admitted_hospital = input} value="0" checked={formdata.policy_holder_admitted_hospital === 0 ? true : false} onChange={() => this.handleRadio('policy_holder_admitted_hospital', 0)} />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_admitted_hospital_details = input} class="form-control" readOnly={formdata.policy_holder_admitted_hospital === 0 ? true : false}></textarea>

                                            {validator.policy_holder_admitted_hospital_details && <span className="help-block form-text text-danger">{validator.policy_holder_admitted_hospital_details}</span>}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                               
                                                <LangData keyname="label_policy_holder_remained_absent" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_remained_absent = input} value="1"
                                                    checked={formdata.policy_holder_remained_absent === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_remained_absent', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_remained_absent = input} value="0"
                                                    checked={formdata.policy_holder_remained_absent === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_remained_absent', 0)} />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_remained_absent_details = input} class="form-control" readOnly={formdata.policy_holder_remained_absent === 0 ? true : false}></textarea>

                                            {validator.policy_holder_remained_absent_details && <span className="help-block form-text text-danger">{validator.policy_holder_remained_absent_details}</span>}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                               
                                                <LangData keyname="label_policy_holder_suffering_ailments_pertaining" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_suffering_ailments_pertaining = input} value="1"
                                                    checked={formdata.policy_holder_suffering_ailments_pertaining === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_suffering_ailments_pertaining', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_suffering_ailments_pertaining = input} value="0"
                                                    checked={formdata.policy_holder_suffering_ailments_pertaining === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_suffering_ailments_pertaining', 0)}
                                                />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_suffering_ailments_pertaining_details = input} class="form-control" readOnly={formdata.policy_holder_suffering_ailments_pertaining === 0 ? true : false}></textarea>

                                            {validator.policy_holder_suffering_ailments_pertaining_details && <span className="help-block form-text text-danger">{validator.policy_holder_suffering_ailments_pertaining_details}</span>}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                              
                                                <LangData keyname="label_policy_holder_suffered_diabetes_others" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_suffered_diabetes_others = input} value="1" checked={formdata.policy_holder_suffered_diabetes_others === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_suffered_diabetes_others', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_suffered_diabetes_others = input} value="0"
                                                    checked={formdata.policy_holder_suffered_diabetes_others === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_suffered_diabetes_others', 0)}
                                                />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_suffered_diabetes_other_details = input} class="form-control" readOnly={formdata.policy_holder_suffered_diabetes_others === 0 ? true : false}></textarea>

                                            {validator.policy_holder_suffered_diabetes_other_details && <span className="help-block form-text text-danger">{validator.policy_holder_suffered_diabetes_other_details}</span>}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                              
                                                <LangData keyname="label_policy_holder_bodily_defect_deformity" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_bodily_defect_deformity = input} value="1"
                                                    checked={formdata.policy_holder_bodily_defect_deformity === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_bodily_defect_deformity', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_bodily_defect_deformity = input} value="0" checked={formdata.policy_holder_bodily_defect_deformity === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_bodily_defect_deformity', 0)}
                                                />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_bodily_defect_deformity_details = input} class="form-control" readOnly={formdata.policy_holder_bodily_defect_deformity === 0 ? true : false}></textarea>

                                            {validator.policy_holder_bodily_defect_deformity_details && <span className="help-block form-text text-danger">{validator.policy_holder_bodily_defect_deformity_details}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                               
                                                <LangData keyname="label_policy_holder_accident_injury" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_accident_injury = input} value="1"
                                                    checked={formdata.policy_holder_accident_injury === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_accident_injury', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_accident_injury = input} value="0"
                                                    checked={formdata.policy_holder_accident_injury === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_accident_injury', 0)}
                                                />

                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_accident_injury_details = input} class="form-control" readOnly={formdata.policy_holder_accident_injury === 0 ? true : false}></textarea>

                                            {validator.policy_holder_accident_injury_details && <span className="help-block form-text text-danger">{validator.policy_holder_accident_injury_details}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                              
                                                <LangData keyname="label_policy_holder_used_alcoholic_others" />
                                                <br></br>
                                                <LangData keyname="label_policy_holder_used_alcoholic_others_option" />

                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_used_alcoholic_others = input} value="1"
                                                    checked={formdata.policy_holder_used_alcoholic_others === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_used_alcoholic_others', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_used_alcoholic_others = input} value="0" checked={formdata.policy_holder_used_alcoholic_others === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_used_alcoholic_others', 0)}
                                                />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_used_alcoholic_other_details = input} class="form-control" readOnly={formdata.policy_holder_used_alcoholic_others === 0 ? true : false}></textarea>

                                            {validator.policy_holder_used_alcoholic_other_details && <span className="help-block form-text text-danger">{validator.policy_holder_used_alcoholic_other_details}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                              
                                                <LangData keyname="label_policy_holder_good_health" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_good_health = input} value="1"
                                                    checked={formdata.policy_holder_good_health === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_good_health', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_good_health = input} value="0"
                                                    checked={formdata.policy_holder_good_health === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_good_health', 0)}
                                                />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_good_health_details = input} class="form-control" readOnly={formdata.policy_holder_good_health === 0 ? true : false}></textarea>

                                            {validator.policy_holder_good_health_details && <span className="help-block form-text text-danger">{validator.policy_holder_good_health_details}</span>}

                                        </div>
                                    </div>
                                </div>

                                <div class="question-single">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <p>
                                                
                                                <LangData keyname="label_policy_holder_aids_hepatitis" />
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_aids_hepatitis = input} value="1"
                                                    checked={formdata.policy_holder_aids_hepatitis === 1 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_aids_hepatitis', 1)}
                                                />
                                                <label class="form-check-label" for="inlineRadio1"><LangData keyname="label_yes" /></label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" ref={(input) => this.policy_holder_aids_hepatitis = input} value="0"
                                                    checked={formdata.policy_holder_aids_hepatitis === 0 ? true : false}
                                                    onChange={() => this.handleRadio('policy_holder_aids_hepatitis', 0)}
                                                />
                                                <label class="form-check-label" ><LangData keyname="label_no" /></label>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <textarea ref={(input) => this.policy_holder_aids_hepatitis_details = input} class="form-control" readOnly={formdata.policy_holder_aids_hepatitis === 0 ? true : false}></textarea>

                                            {validator.policy_holder_aids_hepatitis_details && <span className="help-block form-text text-danger">{validator.policy_holder_aids_hepatitis_details}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5"></div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <label for=""><LangData keyname="label_policy_holder_nid" /><span>*</span></label>
                                        <input type="text" ref={(input) => this.policy_holder_nid = input} placeholder="NID Number" id="" class="form-control" onChange={(e)=>this.handlePorichoy(e)}/>

                                        {validator.policy_holder_nid && <span className="help-block form-text text-danger">{validator.policy_holder_nid}</span>}
                                        {/* {!this.state.nid_validation_err_msg && <span className="help-block form-text text-danger">The NIDD field is required.</span>} */}
                                    </div>

                                    <div class="col-md-4">
                                        <label for=""><LangData keyname="label_policy_holder_email" /><span>*</span></label>
                                        <input type="email" placeholder="E-mail" ref={(input) => this.policy_holder_email = input} class="form-control" />

                                        {validator.policy_holder_email && <span className="help-block form-text text-danger">{validator.policy_holder_email}</span>}
                                        {/* {validator.policy_holder_email && <span className="help-block form-text text-danger">The Email field is required</span>} */}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_policy_holder_name" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Name" ref={(input) => this.policy_holder_name = input} defaultValue={voter?voter.nameEn:''}  id="" class="form-control" />

                                        {validator.policy_holder_name && <span className="help-block form-text text-danger">{validator.policy_holder_name}</span>}
                                    </div>

                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_policy_holder_gender" /><span>*</span></label>
                                        <select readOnly={true} ref={(input) => this.policy_holder_gender = input} className={validator.policy_holder_gender ? 'form-control from-style input-error' : 'form-control from-style'}>
                                            
                                            <option value="">Select Gender</option>
                                            <option value="1" selected={voter_gender == 'male'?true:false}>Male</option>
                                            <option value="2" selected={voter_gender == 'female'?true:false}>Female</option>
                                            <option value="3" selected={voter_gender == 'common'?true:false}>Common</option>


                                        </select>

                                        {validator.policy_holder_gender && <span className="help-block form-text text-danger">{validator.policy_holder_gender}</span>}
                                        {/* {validator.policy_holder_gender && <span className="help-block form-text text-danger">The gender field is required.</span>} */}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="">{/* Father name  */}<LangData keyname="label_policy_holder_father_name" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Father Name" ref={(input) => this.policy_holder_father_name = input} defaultValue={voter?voter.father:''} class="form-control" />

                                        {validator.policy_holder_father_name && <span className="help-block form-text text-danger">The father name field is required.</span>}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for=""><LangData keyname="label_policy_holder_mother_name" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Mother Name" ref={(input) => this.policy_holder_mother_name = input} defaultValue={voter?voter.mother:''} class="form-control" />

                                        {validator.policy_holder_mother_name && <span className="help-block form-text text-danger">{validator.policy_holder_mother_name}</span>}
                                    </div>

                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_policy_holder_spouse" /></label>
                                        <input type="text" placeholder="Spouse" ref={(input) => this.policy_holder_spouse = input} defaultValue={voter?voter.spouse:''} class="form-control" />

                                        {validator.policy_holder_spouse && <span className="help-block form-text text-danger">{validator.policy_holder_spouse}</span>}
                                    </div>
                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_mobile_number" /><span>*</span></label>
                                        <input type="number" placeholder="Mobile Number" ref={(input) => this.policy_holder_mobile_number = input} class="form-control" />

                                        {validator.policy_holder_mobile_number && <span className="help-block form-text text-danger">{validator.policy_holder_mobile_number}</span>}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for=""><LangData keyname="label_policy_holder_parmanent_address" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Parmanent Address" ref={(input) => this.policy_holder_parmanent_address = input} defaultValue={voter?voter.permanentAddress:''} class="form-control" />

                                        {validator.policy_holder_parmanent_address && <span className="help-block form-text text-danger">{validator.policy_holder_parmanent_address}</span>}
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary mb-5" onClick={() => this.handleSubmit()}>
                                                <LangData keyname="label_save_and_payment_now" />
                                </button>
                                
                            </div>
                        </Tab>
                        <Tab title={lang === 'bn'?'মনোনীত তথ্য':'Nominee Information'} className="service-tab-nav">
                            <div className="mt-3">


                            {(this.state.message != '' && this.state.message_type == 'success' && tab_active == 2) && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {(this.state.message != '' && this.state.message_type == 'danger' && tab_active == 2) && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                               
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for=""><LangData keyname="label_nominee_nid" /><span>*</span></label>
                                        <input type="text" placeholder="National ID" ref={(input) => this.nominee_nid = input} class="form-control" onChange={()=>this.handleNomineePorichoy()}/>

                                        {validator.nominee_nid && <span className="help-block form-text text-danger">{validator.nominee_nid}</span>}

                                    </div>
                                    <div class="col-md-4">
                                        <label for=""><LangData keyname="label_nominee_policy_holder_dob" /><span>*</span></label>
                                        <DateInput
                                            id="nominee_dob"
                                            handleNomineePorichoy={() => this.handleNomineePorichoy()}
                                        />
                                        {validator.nominee_dob && <span className="help-block form-text text-danger">{validator.nominee_dob}</span>}

                                       
                                    </div>
                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_nominee_spouse" /></label>
                                        <input type="text" placeholder="Spouse" ref={(input) => this.nominee_spouse = input} defaultValue={nominee_voter?nominee_voter.nominee_spouse:''} class="form-control" />

                                        {validator.nominee_spouse && <span className="help-block form-text text-danger">{validator.nominee_spouse}</span>}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_nominee_name" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Name" ref={(input) => this.nominee_name = input} defaultValue={nominee_voter?nominee_voter.nameEn:''} class="form-control" />

                                        {validator.nominee_name && <span className="help-block form-text text-danger">{validator.nominee_name}</span>}
                                    </div>

                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_policy_holder_gender" /><span>*</span></label>

                                        <select ref={(input) => this.nominee_gender = input} readOnly={true} className={validator.nominee_gender ? 'form-control from-style input-error' : 'form-control from-style'}>
                                            <option value="">Select Gender</option>
                                            <option value="1" selected={nominee_voter_gender == 'male'?true:false}>Male</option>
                                            <option value="2" selected={nominee_voter_gender == 'female'?true:false}>Female</option>
                                            <option value="3" selected={nominee_voter_gender == 'common'?true:false}>Common</option>
                                        </select>

                                        {validator.nominee_gender && <span className="help-block form-text text-danger">{validator.nominee_gender}</span>}
                                    </div>
                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_nominee_father_name" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Father Name" ref={(input) => this.nominee_father_name = input} defaultValue={nominee_voter?nominee_voter.father:''} class="form-control" />

                                        {validator.nominee_father_name && <span className="help-block form-text text-danger">{validator.nominee_father_name}</span>}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for=""> <LangData keyname="label_nominee_mother_name" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Mother Name" ref={(input) => this.nominee_mother_name = input} defaultValue={nominee_voter?nominee_voter.mother:''} class="form-control" />

                                        {validator.nominee_mother_name && <span className="help-block form-text text-danger">{validator.nominee_mother_name}</span>}
                                    </div>

                                    <div class="col-md-8">
                                        <label for=""> <LangData keyname="label_nominee_permanent_address" /><span>*</span></label>
                                        <input readOnly={true} type="text" placeholder="Parmanent Address" ref={(input) => this.nominee_permanent_address = input} defaultValue={nominee_voter?nominee_voter.permanentAddress:''}  class="form-control" />

                                        {validator.nominee_permanent_address && <span className="help-block form-text text-danger">{validator.nominee_permanent_address}</span>}
                                    </div>

                                </div>

                                <button type="button" class="btn btn-primary mb-5" onClick={() => this.handleNomineeSubmit()}>
                                <LangData keyname="label_submit" />
                                </button>
                                
                            </div>
                        </Tab>


                    </Tabs>
                </form>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    sendContactus: (requestData) => { dispatch(sendContactus(requestData)) },
    getPoliciesBasedOnAge: (requestData) => dispatch(getPoliciesBasedOnAge(requestData)),
    getTermsBasedOnPolicy: (requestData) => dispatch(getTermsBasedOnPolicy(requestData)),
    getSumAssuredValidation: (requestData) => dispatch(getSumAssuredValidation(requestData)),
    getCalculations: (requestData) => dispatch(getCalculations(requestData)),
    customerPoricyNid: (requestData) => dispatch(customerPoricyNid(requestData)),
    policyAdd: (requestData) => dispatch(policyAdd(requestData)),
    policyNomineeAdd: (requestData) => dispatch(policyNomineeAdd(requestData)),
})

export default withRouter(connect(null, mapDispatchToProps)(Policyform));